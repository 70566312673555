import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Form, Button } from "react-bootstrap";
import TKLogo from "../../../../../components/TKLogo/TKLogo";
import { tkForgotPasswordRequest } from '../../../../../store/actions/auth';

const ForgotPassword = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  function control() {
    let emailError = null;
    let passwordError = null;
    // const regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

    if (email === "") {
      emailError = "E-posta boş bırakılamaz.";
    }

    if (emailError) {
        setEmailError(emailError);
        return false;
      }
      return true;
  }


  function forgotPassword(e) {
    e.preventDefault();
    if (control()) {
      dispatch(tkForgotPasswordRequest(email));
    }
  }
  return (
    <div className="admin-login-content d-flex justify-content-center">
      <Card className="shadow">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Şifremi Unuttum</i>
              </small>
            </span>
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-5">
          <Form onSubmit={forgotPassword}>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>E-Posta Adresi *</Form.Label>
              <Form.Control
                // type="email"
                placeholder="E-postanızı girin"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(null);
                }}
                isInvalid={emailError}
              />
              <Form.Text className="text-danger">{emailError}</Form.Text>
            </Form.Group>

            <Button  variant="secondary" onClick={ () => {
                history.replace('/admin/login');
              }}>
              Geri Dön
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button  variant="primary" type="submit">
              Eposta gönder
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ForgotPassword;
