import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKGKNAdminRequest,
  setShowGKNAddModal,
  setShowGKNEditModal,
  setShowGKNDeleteModal,
  createTKAdminGKNRequest,
  editTKAdminGKNRequest,
  deleteTKAdminGKNRequest,
} from "../../../../../store/actions/gkns";

import { faqColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKFAQForm from "../../../../../components/AdminLayout/TKForm/TKFAQForm/TKFAQForm";
import { EditorState, convertFromRaw } from "draft-js";
import { isJsonString } from "../../../../../helpers/utilities";

const TKGKN = () => {
  const initialFormData = {
    caption: "",
    detail: "",
    order_number: 0,
  };
  const initialFormDataValidate = {
    caption: {
      required: true,
    },
    detail: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.gkns);
  const { list, processLoading, showAddModal, showEditModal, showDeleteModal } =
    useSelector((state) => state.gkns.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKGKNAdminRequest({}));
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowGKNAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowGKNAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminGKNRequest({
          data: { ...formData },
        })
      );
    }
  };

  const handleSetShowGKNEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowGKNEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const { _id, caption, detail, order_number } = row;
    setSelectedId(_id);
    setFormData({
      caption,
      detail: isJsonString(detail)
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(detail)))
        : detail,
      order_number,
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
    }));
    handleSetShowGKNEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        editTKAdminGKNRequest({
          data: { ...formData },
          selectedId,
        })
      );
    }
  };

  const handleSetShowGKNDeleteModal = (show) => {
    dispatch(setShowGKNDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowGKNDeleteModal(true);
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminGKNRequest({
        selectedId,
      })
    );
  };

  const gknColumns = faqColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="Genç Kart Nedir yönetimi" />
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowGKNAddModal}
        btnTitle="Genç Kart Nedir"
        modalHeader="Genç Kart Nedir ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isCreate={true}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={gknColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
            handleFetchData={filterData}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowGKNEditModal}
        header="Genç Kart Nedir güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isEdit={true}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowGKNDeleteModal}
        header="Genç Kart Nedir silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Genç Kart Nedir başlığını silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKGKN;
