import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
  getTKCategoriesRequest, 
  createTKAdminCategoriesRequest, 
  setShowCategoriesAddModal,
  setShowCategoriesDeleteModal,
  deleteTKAdminCategoriesRequest
} from "../../../../../store/actions/categories";
import { categoriesColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKCategoriesForm from "../../../../../components/AdminLayout/TKForm/TKCategoriesForm/TKCategoriesForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";

const TKCategories = () => {
  const initialFormData = {
    name: ""
  };
  const formDataValidate = {
    name: {
      required: true
    }
  };
  const dispatch = useDispatch();
  const {list, loading} = useSelector(state => state.categories);
  const {processLoading, showAddModal, showDeleteModal} = useSelector(state => state.categories.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const filterData = () => {
    dispatch(getTKCategoriesRequest());
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }))
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowCategoriesAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowCategoriesAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(createTKAdminCategoriesRequest({
        data: {
          ...formData,
          name: formData.name.trim()
        },
        handleSetShowCategoriesAddModal
      }));
    }
  }

  const handleSetShowCategoryDeleteModal = (show) => {
    dispatch(setShowCategoriesDeleteModal(show));
  }

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowCategoryDeleteModal(true);
  }
  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminCategoriesRequest({
      selectedId,
    }));
  }

  const categoriesColumns = categoriesColumnsConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader
        header="kategoriler yönetimi"
      />
      <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowCategoriesAddModal}
        btnTitle="kategori"
        modalHeader="kategori ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKCategoriesForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && 
          <TKDataTable
            columns={categoriesColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
          />
        }
        {loading && <TKLoader/>}
      </TKTableCard>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowCategoryDeleteModal}
        header="Kategori silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kategoriyi silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKCategories;