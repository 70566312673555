import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
  getTKAdminAURequest, 
  createTKAdminAURequest, 
  setShowAUAddModal, 
  editTKAdminAURequest, 
  setShowAUEditModal, 
  deleteTKAdminAURequest, 
  setShowAUDeleteModal 
} from "../../../../../store/actions/adminUsers";
import { adminUsersColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import { userRoles } from "../../../../../constants/general";
import TKAdminUserForm from "../../../../../components/AdminLayout/TKForm/TKAdminUserForm/TKAdminUserForm";

const TKAdminUsers = () => {
  const initialFormData = {
    email: '',
    name: '',
    surname: '',
    password: '',
    links: [],
  };
  const initialFormDataValidate = {
    email: {
      required: true,
      // isEmail: true
    },
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    password: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const {loading} = useSelector(state => state.news);
  const {list, page, totalPages, processLoading, showAddModal, showEditModal, showDeleteModal} = useSelector(state => state.adminUsers.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({...initialFormDataValidate});
  const [adminUsersList, setAdminUsers] = useState([]);
  useEffect(() => {

    filterData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAdminUsers(list.filter(user => user.role === userRoles.ADMIN))
  }, [list])

  const filterData = (page) => {
    dispatch(getTKAdminAURequest({
      page,
    }));
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowAUAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowAUAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(createTKAdminAURequest({
        data: {...formData},
        page,
        handleSetShowAUAddModal
      }));
    }
  }

  const handleSetShowAUEditModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataValidate({...initialFormDataValidate});
      setFormDataErrors({});
    }
    dispatch(setShowAUEditModal(show));
  }

  const handleShowEditModalClick = row => {
    const {_id, email, name, surname, links} = row;
    setSelectedId(_id);
    setFormData({
      email,
      name,
      surname, 
      links,
    });
    setFormDataValidate(prevState => ({
      ...prevState,
      password: {}
    }));
    handleSetShowAUEditModal(true);
  }

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(editTKAdminAURequest({
        data: {...formData},
        selectedId,
        page,
        handleSetShowAUEditModal
      }));
    }
  }

  const handleSetShowAUDeleteModal = show => {
    dispatch(setShowAUDeleteModal(show));
  }

  const handleShowDeleteModalClick = id => {
    setSelectedId(id);
    handleSetShowAUDeleteModal(true);
  }

  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminAURequest({
      selectedId,
      page,
    }));
  }

  const adminUsersColumn = adminUsersColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader
        header="admin kullanıcı yönetimi"
      />

      <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowAUAddModal}
        btnTitle="Kullanıcı"
        modalHeader="kullanıcı ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="lg"
      >
        <TKAdminUserForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && 
          <TKDataTable
            columns={adminUsersColumn}
            data={adminUsersList}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
          />
        }
        {loading && <TKLoader/>}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowAUEditModal}
        header="kullanıcı güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
        size="lg"
      >
        <TKAdminUserForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowAUDeleteModal}
        header="admin kullanıcı silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kullanıcıyı silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKAdminUsers;
