import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { formatDate } from "../../../../../helpers/utilities";
import {
  createTKAdminCHSRequest,
  setShowCHSAddModal,
  editTKAdminCHSRequest,
  setShowCHSEditModal,
  deleteTKAdminCHSRequest,
  setShowCHSDeleteModal,
  setTKAdminCardList,
  getTKAdminCHSRequest,
  setShowCHSBalanceModal,
  isCardNumberAlreadyUsedRequest,
  isTcknAlreadyUsedRequest,
  postTKCHSExportExcelRequest,
} from "../../../../../store/actions/cardHolders";
import { cardHoldersColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKCardHoldersForm from "../../../../../components/AdminLayout/TKForm/TKCardHoldersForm/TKCardHoldersForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKLoadMoneyContainer from "../../../../../components/AdminLayout/TKForm/TKCardHoldersForm/TKLoadMoneyContainer/TKLoadMoneyContainer";
import TKBalance from "../../../../../components/AdminLayout/TKBalance/TKBalance";
import TKExcelExportButton from "../../../../../components/AdminLayout/TKExcelExportButton/TKExcelExportButton";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";
import TKSelect from "../../../../../components/TKSelect/TKSelect";
import { roleLabel } from "../../../../../constants/general";
import { Form } from "react-bootstrap";
import { customSwal } from "../../../../../helpers/customSwal";
import { getTKOrganizationsRequest } from "../../../../../../src/store/actions/organizations";
import { getTKCardTypesRequest } from "../../../../../../src/store/actions/cardTypes";
import { getTKAdminProfessionsRequest } from "../../../../../../src/store/actions/professions";
import TKDatePicker from "../../../../../components/AdminLayout/TKInputs/TKDatePicker/TKDatePicker";

const TKCardHolders = () => {
  const DEFAULT_STATUS = "ACTIVE";

  const SEARCH_PARAMS = [
    {
      name: "tckn",
      holder: "TCKN ile Ara",
    },
    {
      name: "name",
      holder: "Ad ile Ara",
    },
    {
      name: "surname",
      holder: "Soyad ile Ara",
    },
    {
      name: "card",
      holder: "Kart No ile Ara",
    },
  ];

  const initialFormData = {
    name: "",
    surname: "",
    telephone_number: "",
    tckn: "",
    number: "",
    email: "",
    type: [],
    status: DEFAULT_STATUS,
    organization: ["61e17a06765aac008da185df"],
    profession: "",
    birthdate: "",
    sex: "",
    magnetic_code: "",
    food_card_selected: false,
  };
  const initialFormDataValidate = {
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    email: {
      isEmail: true,
    },
    telephone_number: {
      required: true,
      phoneLength: 10,
    },
    tckn: {
      required: true,
      length: 11,
    },
    number: {
      required: true,
      length: 14,
    },
    status: {
      required: true,
    },
    type: {
      required: true,
    },
    profession: {
      required: true,
    },
    sex: {
      required: true,
    },
    birthdate: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.cardHolders);
  const { links } = useSelector((state) => state.auth.user);
  const {
    list,
    page,
    searchFormData,
    totalPages,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
    showBalanceModal,
    cards,
    cardError,
    tcknError,
  } = useSelector((state) => state.cardHolders.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });
  const [smsFlag, setSmsFlag] = useState(false);
  const { listProfessions } = useSelector((state) => state.professions);
  const [filteredListProfessions, filteredSetListProfessions] =
    useState(listProfessions);
  const [selectSearchProfession, setSelectSearchProfession] = useState("");
  const [sexState, setSexState] = useState([
    { sex: "ERKEK", _id: "ERKEK" },
    { sex: "KADIN", _id: "KADIN" },
  ]);
  console.log(formData);

  const rejectReasonInput = useRef();

  useEffect(() => {
    filterData(page);

    dispatch(getTKOrganizationsRequest());
    dispatch(getTKCardTypesRequest());
    dispatch(getTKAdminProfessionsRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId && (showEditModal || showAddModal)) {
      const { cards } = list.find((user) => user._id === selectedId);
      dispatch(setTKAdminCardList(cards ? [...cards] : []));
    }
  }, [list]);

  const doSearch = (name, value) => {
    if (name !== "created_start" && name !== "created_end") {
      value = value.replace("i", "İ");
      value = value.toUpperCase();
    }

    dispatch(
      getTKAdminCHSRequest({
        page: 0,
        searchFormData: {
          ...searchFormData,
          [name]: value,
        },
      })
    );
  };

  const filterData = (page) => {
    dispatch(
      getTKAdminCHSRequest({
        page,
        searchFormData,
      })
    );
  };

  const handleOnChange = (name, value, value2) => {
    if (name === "tckn") dispatch(isTcknAlreadyUsedRequest({ tckn: value }));

    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckCardNumberAlreadyUsed = () => {
    dispatch(isCardNumberAlreadyUsedRequest({ cardNumber: formData.number }));
  };

  const handleCheckTcknAlreadyUsed = () => {
    dispatch(isTcknAlreadyUsedRequest({ tckn: formData.tckn }));
  };

  const checkValidation = () => {
    let validationObj = { ...formDataValidate };
    if (formData.food_card_selected) {
      validationObj = { ...validationObj, magnetic_code: { required: true } };
    }
    const errorMsgs = validate(formData, validationObj);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    if (tcknError !== "") return false;
    return true;
  };

  const handleSetShowCHSAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowCHSAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminCHSRequest({
          data: {
            ...formData,
            name: formData.name.trim(),
            surname: formData.surname.trim(),
          },
          page,
          searchFormData,
          handleSetShowCHSAddModal,
        })
      );
    }
  };

  const handleSetShowCHSEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowCHSEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const {
      _id,
      name,
      surname,
      telephone_number,
      organization,
      profession,
      tckn,
      email,
      status,
      birthdate,
      sex,
      cards,
    } = row;
    setSelectedId(_id);
    if (status === "WFA") {
      setSmsFlag(true);
    } else {
      setSmsFlag(false);
    }
    dispatch(setTKAdminCardList(cards ? [...cards] : []));
    setFormData({
      name,
      surname,
      telephone_number,
      tckn,
      organization,
      email,
      status,
      profession,
      birthdate,
      sex,
    });
    setFormDataValidate({
      name: {
        required: true,
      },
      surname: {
        required: true,
      },
      telephone_number: {
        required: true,
        phoneLength: 10,
      },
      status: {
        required: true,
      },
      tckn: {
        required: true,
        length: 11,
      },
    });
    handleSetShowCHSEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();

    if (check) {
      let user = list.find((user) => user._id === selectedId);
      if (
        user.role == "user" &&
        user.status !== "ACTIVE" &&
        user.cards.length == 0
      ) {
        customSwal("", `Lütfen Kart Ekleyiniz!`, "info");
        return;
      }
      dispatch(
        editTKAdminCHSRequest({
          data: {
            ...formData,
            cards: user.cards,
            balance: user.balance,
          },
          selectedId,
          page,
          searchFormData,
          smsFlag,
          handleSetShowCHSEditModal,
        })
      );
    }
  };

  const handleSetShowCHSDeleteModal = (show) => {
    dispatch(setShowCHSDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowCHSDeleteModal(true);
  };

  const handleDeleteOnClick = () => {
    const rejectreason = rejectReasonInput?.current?.value;
    const user = list.find((r) => r._id === selectedId);
    dispatch(
      deleteTKAdminCHSRequest({
        selectedId,
        rejectreason: rejectreason ? rejectreason : undefined,
        currentStatus: user?.status,
        searchFormData,
        page,
      })
    );
  };

  const handleBalanceOnClick = (row) => {
    setSelectedId(row._id);
    handleSetShowCHSBalanceModal(true);
  };

  const handleSetShowCHSBalanceModal = (show) => {
    dispatch(setShowCHSBalanceModal(show));
  };

  const cardHoldersColumns = cardHoldersColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
    onClickBalance: (row) => handleBalanceOnClick(row),
  });

  const handleExportClick = () => {
    dispatch(
      postTKCHSExportExcelRequest({
        searchFormData,
      })
    );
  };

  const onSelectChange = (name, value) => {
    if (value == "Backspace") {
      filteredSetListProfessions(listProfessions);
      //filteredSetListOrganization(listOrganization);

      setSelectSearchProfession("");
      //setSelectSearchOrganization("");
    } else if (
      value == "Capslock" ||
      value == "ArrowDown" ||
      value == "ArrowUp" ||
      value == "ArrowLeft" ||
      value == "ArrowRight"
    ) {
    } else if (name === "profession") {
      filteredSetListProfessions(
        listProfessions.filter(function (item) {
          if (
            item.name
              .toLowerCase()
              .startsWith((selectSearchProfession + value).toLowerCase())
          ) {
            setSelectSearchProfession(selectSearchProfession + value);

            return item;
          }
        })
      );
    }
    /*
    else if (name === "organization") {
      filteredSetListOrganization(
        listOrganization.filter(function (item) {
          if (
            item.name
              .toLowerCase()
              .startsWith((selectSearchOrganization + value).toLowerCase())
          ) {
            setSelectSearchOrganization(selectSearchOrganization + value);

            return item;
          }
        })
      );
    }
    */
  };

  return (
    <>
      <TKAdminPageHeader header="kart sahipleri yönetimi" />

      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
        isStatus={true}
        status={searchFormData.status}
        cardtype={true}
      />
      <Form.Group>
        <div className="d-flex justify-content-start">
          <TKSelect
            defaultTitle="Meslek Seç"
            selectedItem={searchFormData?.profession}
            itemSelector="name"
            items={filteredListProfessions}
            itemOnClick={(id) => doSearch("profession", id)}
            search={selectSearchProfession}
            name="profession"
            onChange={(value) => onSelectChange("profession", value)}
            filter={true}
          />
          <TKSelect
            defaultTitle="Cinsiyet Seç"
            selectedItem={searchFormData?.sex}
            itemSelector="sex"
            items={sexState}
            itemOnClick={(id) => doSearch("sex", id)}
            name="sex"
          />
        </div>
      </Form.Group>

      <div className="d-flex justify-content-end">
        <TKDatePicker
          dateValue={searchFormData.created_start}
          dateFormat="P"
          placeholder={"Tarih başlangıç"}
          onChange={(date) => doSearch("created_start", date)}
        />
        <TKDatePicker
          dateValue={searchFormData.created_end}
          dateFormat="P"
          placeholder={"Tarih bitiş"}
          onChange={(date) => doSearch("created_end", date)}
        />

        {links.includes("balance") && (
          <>
            <TKLoadMoneyContainer />
          </>
        )}

        <TKExcelExportButton
          onClick={handleExportClick}
          btnTitle="Dışa Aktar"
        ></TKExcelExportButton>

        <TKAddNewButton
          showModal={showAddModal}
          setShowModal={handleSetShowCHSAddModal}
          btnTitle="Yeni Kart"
          modalHeader="kart sahibi ekleme formu"
          handleSaveOnClick={handleSaveOnClick}
          processLoading={processLoading}
          size="lg"
        >
          <TKCardHoldersForm
            formData={formData}
            formDataErrors={formDataErrors}
            onChange={handleOnChange}
            checkCardNumberAlreadyUsed={handleCheckCardNumberAlreadyUsed}
            checkTcknAlreadyUsed={handleCheckTcknAlreadyUsed}
            cardError={cardError}
            tcknError={tcknError}
          />
        </TKAddNewButton>
      </div>

      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={cardHoldersColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowCHSEditModal}
        header="kart sahibi güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
        size="xl"
      >
        <TKCardHoldersForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          checkCardNumberAlreadyUsed={handleCheckCardNumberAlreadyUsed}
          checkTcknAlreadyUsed={handleCheckTcknAlreadyUsed}
          cardError={cardError}
          tcknError={tcknError}
          isEdit={true}
          cardsList={cards.list}
          selectedId={selectedId}
          user={list.find((user) => user._id === selectedId)}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowCHSDeleteModal}
        header={
          list.find((r) => r._id === selectedId)?.status
            ? "Kart Sahibi Başvuru Reddetme İşlemi"
            : "kart sahibi silme işlemi"
        }
        saveBtnTitle={
          list.find((r) => r._id === selectedId)?.status === "WFA"
            ? "Reddet"
            : "sil"
        }
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
        // size="lg"
      >
        {list.find((r) => r._id === selectedId)?.status === "WFA" ? (
          <div>
            <div className="my-1">
              Başvuruyu reddetmek üzeresiniz, onaylıyor musunuz?
            </div>
            <div className="my-1">
              Onaylıyorsanız ret sebebini aşağıda belirtiniz:
            </div>
            <Form.Control
              as="textarea"
              ref={rejectReasonInput}
              placeHolder="Ret sebebi..."
              className="w-100"
              rows="4"
            />
          </div>
        ) : (
          "kart sahibi silmek üzeresiniz, onaylıyor musunuz?"
        )}
      </TKModal>
      <TKModal
        show={showBalanceModal}
        setShow={handleSetShowCHSBalanceModal}
        header="Kart Sahibi"
        saveBtnTitle="Tamam"
        saveOnClick={(a) => handleSetShowCHSBalanceModal(false)}
        processLoading={processLoading}
        cancelButtonHide={true}
      >
        <TKBalance
          user={list.find((user) => user._id === selectedId)}
          id={selectedId}
          candeposit={links}
        />
      </TKModal>
    </>
  );
};

export default TKCardHolders;
