import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Form, Button } from "react-bootstrap";
import TKLogo from "../../../../../components/TKLogo/TKLogo";
import { tkLoginRequest } from '../../../../../store/actions/auth';

const Login = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  function control() {
    let emailError = null;
    let passwordError = null;
    // const regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

    if (email === "") {
      emailError = "E-posta boş bırakılamaz.";
    }
    if (password === "") {
      passwordError = "Parola boş bırakılamaz.";
    }

    if (password !== "" || email !== "") {
      if (password.length < 5) {
        passwordError = "Geçersiz parola.";
      }
      if (email !== "") {
        // if (!regexp.test(email)) {
        //   emailError = "Geçersiz e-posta.";
        // }
      }
    } else {
      if (password === "") {
        passwordError = "Parola girin.";
      }
      if (email === "") {
        emailError = "E-posta girin.";
      }
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    }
    return true;
  }

  function login(e) {
    e.preventDefault();
    if (control()) {
      dispatch(tkLoginRequest(history, email, password));
    }
  }
  return (
    <div className="admin-login-content d-flex justify-content-center">
      <Card className="shadow">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Admin Giriş</i>
              </small>
            </span>
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-5">
          <Form onSubmit={login}>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>E-Posta Adresi</Form.Label>
              <Form.Control
                // type="email"
                placeholder="E-posta girin"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(null);
                }}
                isInvalid={emailError}
              />
              <Form.Text className="text-danger">{emailError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Parola</Form.Label>
              <Form.Control
                type="password"
                placeholder="Parola"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(null);
                }}
                isInvalid={passwordError}
              />
              <Form.Text className="text-danger">{passwordError}</Form.Text>
            </Form.Group>
            <div className="d-flex w-100 justify-content-end">
              <Button  variant="link" size="sm"  onClick={ () => {
                  history.replace('/admin/forgotpassword');
                }}>
                Şifremi Unuttum
              </Button>
              &nbsp;
              <Button  variant="primary" type="submit">
                Giriş
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
