import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import TKIcon from "../../../../components/TKIcon/TKIcon";
import TKLogo from "../../../../components/TKLogo/TKLogo";
import TKUserCircleDropdown from "../../../../components/AdminLayout/TKUserCircleDropdown/TKUserCircleDropdown";
import "./MainLayout.scss";

const MainTopBar = () => {
  function toggleSidebar() {
    const sidebar = document.getElementById("sidebar-wrapper");
    const content = document.getElementById("content-wrapper");
    const sidebarContent = document.getElementById("sidebar-content-wrapper");
    if (sidebar.classList.contains("sidebar-open")) {
      sidebar.classList.remove("sidebar-open");
      sidebar.classList.add("sidebar-close");

      sidebarContent.classList.remove("sidebar-content-open");
      sidebarContent.classList.add("sidebar-content-close");

      content.classList.remove("content-open");
      content.classList.add("content-close");
    } else {
      sidebar.classList.add("sidebar-open");
      sidebar.classList.remove("sidebar-close");

      setTimeout(() => {
        sidebarContent.classList.remove("sidebar-content-close");
        sidebarContent.classList.add("sidebar-content-open");
      }, 250);

      content.classList.remove("content-close");
      content.classList.add("content-open");
    }
  }

  return (
    <Navbar className="topbar justify-content-between px-3" bg="primary">
      <div className="d-flex align-items-center">
        <TKIcon
          className="mr-4"
          name="menu-burger"
          size="20"
          onClick={() => toggleSidebar()}
        />
        <Link
          className="d-flex align-items-center navbar-brand"
          to="/admin/news"
        >
          <TKLogo size={150} />
          <small className="ml-1">
            <i className="text-white">Yönetim Ekranı</i>
          </small>
        </Link>
      </div>
      <TKUserCircleDropdown size={30} iconSize="22" />
    </Navbar>
  );
};

export default MainTopBar;
