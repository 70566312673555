import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { userRoles } from "../../constants/general";
import { useSelector } from "react-redux";

function PrivateRoute({ exact, path, render }) {
  const isAuthenticated = useAuth();
  const { role, links } = useSelector(state => state.auth.user);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        // isAuthenticated /*&& role === userRoles.ADMIN*/ ? render(props) : <Redirect to="/admin/login" />
        const pathName = path.split("/").reverse()[0];
        if(isAuthenticated && links.includes(pathName)) {
          return render(props);
        } else {
          return <Redirect to="/admin/login" />;
        }
      }
      }
    />
  );
}

export default PrivateRoute;
