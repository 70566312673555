import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKFAQAdminRequest,
  setShowFAQAddModal,
  setShowFAQEditModal,
  setShowFAQDeleteModal,
  createTKAdminFAQRequest,
  editTKAdminFAQRequest,
  deleteTKAdminFAQRequest,
} from "../../../../../store/actions/faqs";

import { faqColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKFAQForm from "../../../../../components/AdminLayout/TKForm/TKFAQForm/TKFAQForm";
import { EditorState, convertFromRaw } from "draft-js";
import { isJsonString } from "../../../../../helpers/utilities";

const TKFAQ = () => {
  const initialFormData = {
    caption: "",
    detail: "",
    order_number: 0,
  };
  const initialFormDataValidate = {
    caption: {
      required: true,
    },
    detail: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.faqs);
  const {
    list,
    page,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
  } = useSelector((state) => state.faqs.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKFAQAdminRequest({}));
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowFAQAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowFAQAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminFAQRequest({
          data: { ...formData },
        })
      );
    }
  };

  const handleSetShowFAQEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowFAQEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const { _id, caption, detail, order_number } = row;
    setSelectedId(_id);
    setFormData({
      caption,
      detail: detail,
      order_number,
      order_number,
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
    }));
    handleSetShowFAQEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        editTKAdminFAQRequest({
          data: { ...formData },
          selectedId,
        })
      );

      //reset form data
      const newData = {
        caption: "",
        detail: "",
      };
      setFormData({ ...newData });
    }
  };

  const handleSetShowFAQDeleteModal = (show) => {
    dispatch(setShowFAQDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowFAQDeleteModal(true);
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminFAQRequest({
        selectedId,
      })
    );
  };

  const faqColumns = faqColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="SSS yönetimi" />
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowFAQAddModal}
        btnTitle="SSS"
        modalHeader="SSS ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isCreate={true}
          isFAQ={true}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={faqColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            handleFetchData={filterData}
            pagination={false}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowFAQEditModal}
        header="SSS güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isFAQ={true}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowFAQDeleteModal}
        header="SSS işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        SSS yu silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKFAQ;
