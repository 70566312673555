import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
  getTKCardTypesRequest, 
  createTKAdminCardTypesRequest, 
  setShowCardTypesAddModal,
  setShowCardTypesDeleteModal,
  deleteTKAdminCardTypesRequest,
  searchTKCardTypesRequest
} from "../../../../../store/actions/cardTypes";
import { cardTypesColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKCardTypesForm from "../../../../../components/AdminLayout/TKForm/TKCardTypesForm/TKCardTypesForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";

const TKCardTypes = () => {

  const SEARCH_PARAMS = 
  [ {
    name: 'search',
    holder: 'Kart Tipi Adı ile Ara'
    }
  ];

  const initialFormData = {
    name: ""
  };
  const formDataValidate = {
    name: {
      required: true
    }
  };
  const dispatch = useDispatch();
  const {listCardTypes, loading,search} = useSelector(state => state.cardTypes);
  const {processLoading, showAddModal, showDeleteModal} = useSelector(state => state.cardTypes.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);


  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const filterData = () => {
    dispatch(getTKCardTypesRequest());
  }

  const doSearch = (name,value) => {
    dispatch(searchTKCardTypesRequest({
      search:value
    }));
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }))
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowCardTypesAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowCardTypesAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(createTKAdminCardTypesRequest({
        data: {
          ...formData,
          name: formData.name.trim()
        },
        handleSetShowCardTypesAddModal
      }));
    }
  }

  const handleSetShowCardTypesDeleteModal = (show) => {
    dispatch(setShowCardTypesDeleteModal(show));
  }

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowCardTypesDeleteModal(true);
  }
  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminCardTypesRequest({
      selectedId,
    }));
  }

  const cardTypesColumns = cardTypesColumnsConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader
        header="Kart Tipleri Yönetimi"
      />
      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
      />
      <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowCardTypesAddModal}
        btnTitle="Kart Tipleri"
        modalHeader="Kart Tipleri ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKCardTypesForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && 
          <TKDataTable
            columns={cardTypesColumns}
            data={listCardTypes}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
          />
        }
        {loading && <TKLoader/>}
      </TKTableCard>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowCardTypesDeleteModal}
        header="Kart Tipi silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kart Tipini silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKCardTypes;