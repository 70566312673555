import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
  getTKOrganizationsRequest, 
  createTKAdminOrganizationsRequest, 
  setShowOrganizationsAddModal,
  setShowOrganizationsDeleteModal,
  deleteTKAdminOrganizationsRequest,
  searchTKOrganizationsRequest
} from "../../../../../store/actions/organizations";
import { organizationsColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKOrganizationsForm from "../../../../../components/AdminLayout/TKForm/TKOrganizationsForm/TKOrganizationsForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";

const TKOrganizations = () => {

  const SEARCH_PARAMS = 
  [ {
    name: 'search',
    holder: 'Organizasyon Adı ile Ara'
    }
  ];
  const initialFormData = {
    name: ""
  };
  const formDataValidate = {
    name: {
      required: true
    }
  };
  const dispatch = useDispatch();
  const {listOrganization,search, page,totalPages, loading} = useSelector(state => state.organizations);
  const {processLoading, showAddModal, showDeleteModal} = useSelector(state => state.organizations.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    filterData(page,'');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const filterData = (page,search) => {
    dispatch(searchTKOrganizationsRequest({
      page,
      search
    }));
  }


  
  const doSearch = (name,value) => {
    dispatch(searchTKOrganizationsRequest({
      page:0,
      search:value
    }));
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }))
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowOrganizationsAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowOrganizationsAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(createTKAdminOrganizationsRequest({
        data: {
          ...formData,
          name: formData.name.trim()
        },
        handleSetShowOrganizationsAddModal,
        page,
        search
      }));
    }
  }

  const handleSetShowOrganizationDeleteModal = (show) => {
    dispatch(setShowOrganizationsDeleteModal(show));
  }

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowOrganizationDeleteModal(true);
  }
  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminOrganizationsRequest({
      selectedId,
      page,
      search
    }));
  }

  const organizationsColumns = organizationsColumnsConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader
        header="Organizasyon Yönetimi"
      />
      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
      />
      <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowOrganizationsAddModal}
        btnTitle="Organizasyon"
        modalHeader="Organizasyon ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKOrganizationsForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && 
          <TKDataTable
            columns={organizationsColumns}
            data={listOrganization}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            activePage={page}
            totalPages={totalPages}
            setSortDirection={setSortDirection}
            handleFetchData={filterData}
            searchedText={search}
          />
        }
        {loading && <TKLoader/>}
      </TKTableCard>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowOrganizationDeleteModal}
        header="Organizasyon silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Organizasyonu silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKOrganizations;