import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKAdminProfessionsRequest,
  createTKAdminProfessionsRequest,
  setShowProfessionsAddModal,
  setShowProfessionsDeleteModal,
  deleteTKAdminProfessionsRequest,
  searchTKProfessionsRequest,
} from "../../../../../store/actions/professions";
import { professionsColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKProfessionsForm from "../../../../../components/AdminLayout/TKForm/TKProfessionsForm/TKProfessionsForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";

const TKProfession = () => {
  const SEARCH_PARAMS = [
    {
      name: "search",
      holder: "Meslek Adı ile Ara",
    },
  ];

  const initialFormData = {
    name: "",
  };
  const formDataValidate = {
    name: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { listProfessions, loading, search } = useSelector(
    (state) => state.professions
  );
  const { processLoading, showAddModal, showDeleteModal } = useSelector(
    (state) => state.professions.admin
  );
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKAdminProfessionsRequest());
  };

  const doSearch = (name, value) => {
    dispatch(
      searchTKProfessionsRequest({
        search: value,
      })
    );
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowProfessionsAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowProfessionsAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminProfessionsRequest({
          data: {
            ...formData,
            name: formData.name.trim(),
          },
          handleSetShowProfessionsAddModal,
        })
      );
    }
  };

  const handleSetShowProfessionsDeleteModal = (show) => {
    dispatch(setShowProfessionsDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowProfessionsDeleteModal(true);
  };
  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminProfessionsRequest({
        selectedId,
      })
    );
  };

  const professionsColumns = professionsColumnsConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="Meslekler Yönetimi" />
      <TKSearchText searchparams={SEARCH_PARAMS} doSearch={doSearch} />
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowProfessionsAddModal}
        btnTitle="Meslekler"
        modalHeader="Meslek ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKProfessionsForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={professionsColumns}
            data={listProfessions}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowProfessionsDeleteModal}
        header="Meslek silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
       Mesleği silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKProfession;
