import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  complainColumnsConfig,
  transactionsConfig,
} from "../../../../../constants/columnsConfigurations";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  tkGetAllTransactionsRequest,
  tkPostAllTransactionsExportExcelRequest,
} from "../../../../../store/actions/allTransactions";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";
import TKExcelExportButton from "../../../../../components/AdminLayout/TKExcelExportButton/TKExcelExportButton";
import TKDatePicker from "../../../../../components/AdminLayout/TKInputs/TKDatePicker/TKDatePicker";

const TKTransactions = () => {
  const initialSearchFormData = {
    tckn: "",
    card: "",
    type: "",
    created_end: null,
    created_start: null,
    cardtype: "",
  };

  const dispatch = useDispatch();
  const { list, page, totalPages, processLoading, showViewModal } = useSelector(
    (state) => state.allTransactions.admin
  );
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const { loading } = useSelector((state) => state.complains);
  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  useEffect(() => {
    filterData(page, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSearch = (name, value, stat) => {
    console.log(name + " " + value);

    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(
      tkGetAllTransactionsRequest({
        page: 0,
        searchFormData: {
          ...searchFormData,
          [name]: value,
        },
      })
    );
  };

  const SEARCH_PARAMS = [
    {
      name: "tckn",
      holder: "TCKN ile Ara",
    },
    {
      name: "card",
      holder: "Kart No ile Ara",
    },
    {
      name: "type",
      holder: "İşlem Tipi ile Ara",
    },
  ];

  const filterData = (page) => {
    dispatch(
      tkGetAllTransactionsRequest({
        page,
        searchFormData,
      })
    );
  };

  const transactionsColumns = transactionsConfig({});

  const handleExportClick = () => {
    dispatch(
      tkPostAllTransactionsExportExcelRequest({
        searchFormData,
      })
    );
  };
  return (
    <>
      <TKAdminPageHeader header="İşlemler" />
      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
        cardtype={true}
      />
      <div className="d-flex justify-content-end">
        <TKDatePicker
          dateValue={searchFormData.created_start}
          dateFormat="P"
          placeholder={"Tarih başlangıç"}
          onChange={(date) => doSearch("created_start", date)}
        />
        <TKDatePicker
          dateValue={searchFormData.created_end}
          dateFormat="P"
          placeholder={"Tarih bitiş"}
          onChange={(date) => doSearch("created_end", date)}
        />
        <TKExcelExportButton
          onClick={handleExportClick}
          btnTitle="Dışa Aktar"
        ></TKExcelExportButton>
      </div>

      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={transactionsColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
            pagination={true}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
    </>
  );
};

export default TKTransactions;
